// src/App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import TopHeader from './components/TopHeader'; // Import the TopHeader component
import MapView from './components/MapView';
import PlaceList from './components/PlaceList';
import SearchFilter from './components/SearchFilter';
import Footer from './components/Footer'; // Import Footer
import Papa from 'papaparse';

function App() {
  const [places, setPlaces] = useState([]);
  const [filteredPlaces, setFilteredPlaces] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [zipCodes, setZipCodes] = useState({});
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [radius, setRadius] = useState(20);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [locationLoaded, setLocationLoaded] = useState(false);
  const [locationError, setLocationError] = useState(null);

  useEffect(() => {
    // Parse places data.csv
    Papa.parse('/data.csv', {
      header: true,
      download: true,
      skipEmptyLines: true,
      complete: (results) => {
        const data = results.data
          .filter((place) => place.Name && place.Latitude && place.Longitude)
          .map((place) => ({
            id: place.id || `${place.Name}-${place.Latitude}-${place.Longitude}`, // Ensure unique id
            name: place.Name,
            address: place.Address,
            city: place.City,
            state: place.State,
            zip: place["ZIP Code"],
            lat: parseFloat(place.Latitude),
            long: parseFloat(place.Longitude),
            email: place.Email,
            phones: place["Phone Number"],
            tags: place.Tags ? place.Tags.split(',').map((tag) => tag.trim()) : [],
          }));
        console.log('Loaded places:', data);
        setPlaces(data);
        setFilteredPlaces(data);
        setDataLoaded(true);
      },
      error: (error) => {
        console.error('Error parsing data.csv:', error);
      },
    });

    // Parse zipcodes.csv
    Papa.parse('/zipcodes.csv', {
      header: true,
      download: true,
      skipEmptyLines: true,
      complete: (results) => {
        const zipData = {};
        results.data.forEach((entry) => {
          zipData[entry.zip] = {
            lat: parseFloat(entry.lat),
            lng: parseFloat(entry.lng),
          };
        });
        console.log('Loaded zip codes:', zipData);
        setZipCodes(zipData);
      },
      error: (error) => {
        console.error('Error parsing zipcodes.csv:', error);
      },
    });
  }, []);

  // Fetch user's current location after data is loaded
  useEffect(() => {
    if (dataLoaded) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const location = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            console.log('User Location:', location); // Debugging
            setUserLocation(location);
            setLocationLoaded(true);
          },
          (error) => {
            console.error('Error obtaining geolocation:', error);
            setLocationError('Unable to retrieve your location.');
            setLocationLoaded(true);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
        setLocationError('Geolocation is not supported by your browser.');
        setLocationLoaded(true);
      }
    }
  }, [dataLoaded]);

  // Filter places when data and location are loaded
  useEffect(() => {
    if (dataLoaded && locationLoaded && userLocation) {
      filterPlacesByLocation(userLocation.lat, userLocation.lng, radius);
    } else if (dataLoaded && locationLoaded && !userLocation) {
      // If location is not available, clear filtered places
      setFilteredPlaces([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded, locationLoaded, userLocation, radius]);

  // Function to calculate distance between two points in miles
  const getDistanceFromLatLonInMiles = (lat1, lon1, lat2, lon2) => {
    function deg2rad(deg) {
      return deg * (Math.PI / 180);
    }
    const R = 3958.8; // Radius of the earth in miles
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  };

  // Handle ZIP code search
  const handleSearch = (zip) => {
    const location = zipCodes[zip];
    if (location) {
      console.log('ZIP Code Location:', location); // Debugging
      setUserLocation(location);
      filterPlacesByLocation(location.lat, location.lng, radius);
      setSelectedPlace(null); // Deselect any selected place
    } else {
      alert('ZIP code not found.');
    }
  };

  // Handle tag filtering
  const handleFilter = (tags) => {
    setSelectedTags(tags);
    if (tags.length === 0) {
      if (userLocation) {
        filterPlacesByLocation(userLocation.lat, userLocation.lng, radius);
      } else {
        setFilteredPlaces([]);
      }
    } else {
      filterPlacesByLocation(userLocation.lat, userLocation.lng, radius);
    }
    setSelectedPlace(null); // Deselect any selected place
  };

  // Handle radius change
  const handleRadiusChange = (newRadius) => {
    setRadius(newRadius);
    if (userLocation) {
      filterPlacesByLocation(userLocation.lat, userLocation.lng, newRadius);
    }
    setSelectedPlace(null); // Deselect any selected place
  };

  // Filter places based on location and radius
  const filterPlacesByLocation = (lat, lng, currentRadius) => {
    let filtered = places.filter((place) => {
      const distance = getDistanceFromLatLonInMiles(lat, lng, place.lat, place.long);
      return distance <= currentRadius;
    });

    if (selectedTags.length > 0) {
      filtered = filtered.filter((place) =>
        selectedTags.some((tag) => place.tags.includes(tag))
      );
    }

    setFilteredPlaces(filtered);
  };

  // Handle place selection from the list or map
  const handleSelectPlace = (place) => {
    setSelectedPlace(place);
  };

  return (
    <>
      <TopHeader />
      <div className="container">
        {dataLoaded && locationLoaded ? (
          userLocation || selectedPlace ? (
            <SearchFilter
              onSearch={handleSearch}
              onFilter={handleFilter}
              places={places}
              radius={radius}
              onRadiusChange={handleRadiusChange}
            />
          ) : locationError ? (
            <p className="error-message">{locationError}</p>
          ) : (
            <p>Fetching your location...</p>
          )
        ) : (
          <p>Loading data and fetching your location...</p>
        )}
        <div className="content">
          <PlaceList
            places={filteredPlaces}
            onSelectPlace={handleSelectPlace}
            selectedPlace={selectedPlace}
          />
          {dataLoaded && locationLoaded ? (
            <MapView
              places={filteredPlaces}
              userLocation={userLocation}
              selectedPlace={selectedPlace}
              radius={radius}
              onSelectPlace={handleSelectPlace}
            />
          ) : (
            <div className="map-placeholder">
              <p>Map will appear here once data is loaded and location is determined.</p>
            </div>
          )}
        </div>
      </div>
      <Footer /> {/* Add Footer here */}
    </>
  );
}

export default App;
