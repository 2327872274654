// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/TopHeader.css */
.top-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #14171b;
  padding: 11px;
  color: white;
  width: 100%;
  margin: 0;
  position: relative;
  box-sizing: border-box;
}

.top-header img {
  position: absolute;
  left: 20px;
  height: 50px;
  width: auto;
}

.top-header h1 {
  font-size: 24px;
  margin: 0;
  font-weight: bold;
}

@media (max-width: 768px) {
  .top-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .top-header img {
    position: static;
    margin-bottom: 10px;
  }

  .top-header h1 {
    margin-top: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/TopHeader.css"],"names":[],"mappings":"AAAA,iCAAiC;AACjC;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;EACzB,aAAa;EACb,YAAY;EACZ,WAAW;EACX,SAAS;EACT,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,eAAe;EACf,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE;IACE,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":["/* src/components/TopHeader.css */\n.top-header {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: #14171b;\n  padding: 11px;\n  color: white;\n  width: 100%;\n  margin: 0;\n  position: relative;\n  box-sizing: border-box;\n}\n\n.top-header img {\n  position: absolute;\n  left: 20px;\n  height: 50px;\n  width: auto;\n}\n\n.top-header h1 {\n  font-size: 24px;\n  margin: 0;\n  font-weight: bold;\n}\n\n@media (max-width: 768px) {\n  .top-header {\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n  }\n\n  .top-header img {\n    position: static;\n    margin-bottom: 10px;\n  }\n\n  .top-header h1 {\n    margin-top: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
