// src/components/PlaceList.js
import React from 'react';
import './PlaceList.css'; // Import PlaceList-specific styles

function PlaceList({ places, onSelectPlace, selectedPlace }) {
  return (
    <div className="place-list-container">
      <h2 className="place-list-header">Medical Providers Found: {places.length}</h2>
      <div className="place-list">
        {places.length === 0 ? (
          <p>No places found for the selected criteria.</p>
        ) : (
          <ul>
            {places.map((place) => (
              <li
                key={place.id}
                onClick={() =>
                  selectedPlace && selectedPlace.id === place.id
                    ? onSelectPlace(null)
                    : onSelectPlace(place)
                }
                className={`place-item ${
                  selectedPlace && selectedPlace.id === place.id ? 'selected' : ''
                }`}
              >
                <h3>{place.name}</h3>
                <p>
                  {place.address}, {place.city}, {place.state} {place.zip}
                </p>
                <p>
                  Email: <a href={`mailto:${place.email}`}>{place.email}</a>
                </p>
                <p>
                  Phone: <a href={`tel:${place.phones}`}>{place.phones}</a>
                </p>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default PlaceList;
