// src/components/MapView.js
import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Circle, useMap } from 'react-leaflet';
import { useMapEvent } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './MapView.css'; // Import MapView-specific styles

// Fix default icon issues with Leaflet in React
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Component to update map view based on center and zoom
function ChangeView({ center, zoom }) {
  const map = useMap();

  useEffect(() => {
    if (center && zoom != null) {
      map.setView(center, zoom, { animate: true });
    }
  }, [center, zoom, map]);

  return null;
}

// Component to handle map clicks for deselection
function MapClickHandler({ onDeselect }) {
  useMapEvent('click', () => {
    onDeselect();
  });
  return null;
}

function MapView({ places, userLocation, selectedPlace, radius, onSelectPlace }) {
  // Determine the map center based on userLocation or selectedPlace
  const center = selectedPlace
    ? [selectedPlace.lat, selectedPlace.long]
    : userLocation
    ? [userLocation.lat, userLocation.lng]
    : [0, 0]; // Default center

  // Determine the appropriate zoom level based on radius
  const calculateZoomLevel = (radius) => {
    if (radius <= 10) return 11;
    if (radius <= 20) return 10;
    if (radius <= 30) return 9;
    if (radius <= 50) return 7.5;
    if (radius <= 100) return 7;
    if (radius <= 1000) return 4;
    return 3.75; // For radius > 1000 miles
  };

  // Set zoom level based on whether a place is selected
  const zoom = selectedPlace ? 15 : calculateZoomLevel(radius);

  // Refs for markers to control popups
  const markerRefs = useRef({});

  useEffect(() => {
    if (selectedPlace && markerRefs.current[selectedPlace.id]) {
      markerRefs.current[selectedPlace.id].openPopup();
    }
  }, [selectedPlace]);

  return (
    <div className="map-view">
      <MapContainer
        center={center}
        zoom={zoom}
        style={{ height: '77vh', width: '100%', border: 'solid' }}
        key={selectedPlace ? selectedPlace.id : `map-${radius}-${userLocation?.lat}-${userLocation?.lng}`}
      >
        {/* Change the view when center or zoom changes */}
        <ChangeView center={center} zoom={zoom} />

        {/* Handle map clicks to deselect selected place */}
        <MapClickHandler onDeselect={() => onSelectPlace(null)} />

        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; OpenStreetMap contributors"
        />

        {/* User Location Marker and Circle (only if userLocation and no selectedPlace) */}
        {userLocation && !selectedPlace && (
          <>
            <Marker position={[userLocation.lat, userLocation.lng]}>
              <Popup>Your Location</Popup>
            </Marker>
            <Circle
              center={[userLocation.lat, userLocation.lng]}
              radius={radius * 1609.34} // Convert miles to meters
              pathOptions={{ color: 'blue', fillColor: 'blue', fillOpacity: 0.1 }}
            />
          </>
        )}

        {/* Selected Place Marker */}
        {selectedPlace && (
          <>
            {userLocation && (
              <Marker position={[userLocation.lat, userLocation.lng]}>
                <Popup>Your Location</Popup>
              </Marker>
            )}
            <Marker
              position={[selectedPlace.lat, selectedPlace.long]}
              ref={(ref) => {
                if (ref) {
                  markerRefs.current[selectedPlace.id] = ref;
                }
              }}
            >
              <Popup>
                <strong>{selectedPlace.name}</strong>
                <br />
                {selectedPlace.address}, {selectedPlace.city}, {selectedPlace.state} {selectedPlace.zip}
                <br />
                Email: <a href={`mailto:${selectedPlace.email}`}>{selectedPlace.email}</a>
                <br />
                Phone: <a href={`tel:${selectedPlace.phones}`}>{selectedPlace.phones}</a>
                <br />
                Tags: {selectedPlace.tags.join(', ')}
              </Popup>
            </Marker>
          </>
        )}

        {/* All Other Places (render only if no place is selected) */}
        {!selectedPlace &&
          places.map((place) => (
            <Marker
              key={place.id}
              position={[place.lat, place.long]}
              ref={(ref) => {
                if (ref) {
                  markerRefs.current[place.id] = ref;
                }
              }}
              eventHandlers={{
                click: () => {
                  onSelectPlace(place);
                },
              }}
            >
              <Popup>
                <strong>{place.name}</strong>
                <br />
                {place.address}, {place.city}, {place.state}, {place.zip}
                <br />
                Email: <a href={`mailto:${place.email}`}>{place.email}</a>
                <br />
                Phone: <a href={`tel:${place.phones}`}>{place.phones}</a>
                <br />
                Tags: {place.tags.join(', ')}
              </Popup>
            </Marker>
          ))}
      </MapContainer>
    </div>
  );
}

export default MapView;
