// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/index.css */

/* Reset some default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set a global font and background */
body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f9f9f9;
  color: #333;
}

/* Style links */
a {
  color: #1e90ff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Optional: Style the root container */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB,sCAAsC;AACtC;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA,qCAAqC;AACrC;EACE,yCAAyC;EACzC,yBAAyB;EACzB,WAAW;AACb;;AAEA,gBAAgB;AAChB;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA,uCAAuC;AACvC;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB","sourcesContent":["/* src/index.css */\n\n/* Reset some default browser styles */\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n/* Set a global font and background */\nbody {\n  font-family: Arial, Helvetica, sans-serif;\n  background-color: #f9f9f9;\n  color: #333;\n}\n\n/* Style links */\na {\n  color: #1e90ff;\n  text-decoration: none;\n}\n\na:hover {\n  text-decoration: underline;\n}\n\n/* Optional: Style the root container */\n#root {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
