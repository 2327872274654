// src/components/Footer.js
import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <p>Made by Shamri Alam</p>
    </div>
  );
}

export default Footer;
