// src/components/TopHeader.js
import React from 'react';
import './TopHeader.css';

function TopHeader() {
  return (
    <div className="top-header">
      <img src="/sst.png" alt="Logo" />
      <h1>Medical Provider Finder</h1>
    </div>
  );
}

export default TopHeader;
