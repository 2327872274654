// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // Use 'react-dom' if using React 17 or below
import './index.css'; // Global styles
import App from './App'; // Import the main App component

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
