// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/MapView.css */
.map-view {
  border: solid #000000 0.1px;
  width: 60%;
  height: 50vh;
  position: relative;
  
}

@media (max-width: 1200px) {
  .map-view {
    width: 100%;
    height: 60vh;
 
  }
}

@media (max-width: 768px) {
  .map-view {
    width: 100%;
    height: 50vh;

  }
}
`, "",{"version":3,"sources":["webpack://./src/components/MapView.css"],"names":[],"mappings":"AAAA,+BAA+B;AAC/B;EACE,2BAA2B;EAC3B,UAAU;EACV,YAAY;EACZ,kBAAkB;;AAEpB;;AAEA;EACE;IACE,WAAW;IACX,YAAY;;EAEd;AACF;;AAEA;EACE;IACE,WAAW;IACX,YAAY;;EAEd;AACF","sourcesContent":["/* src/components/MapView.css */\n.map-view {\n  border: solid #000000 0.1px;\n  width: 60%;\n  height: 50vh;\n  position: relative;\n  \n}\n\n@media (max-width: 1200px) {\n  .map-view {\n    width: 100%;\n    height: 60vh;\n \n  }\n}\n\n@media (max-width: 768px) {\n  .map-view {\n    width: 100%;\n    height: 50vh;\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
