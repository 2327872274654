// src/components/SearchFilter.js
import React from 'react';
import Select from 'react-select';
import './SearchFilter.css'; // Import SearchFilter-specific styles

function SearchFilter({ onSearch, onFilter, places, radius, onRadiusChange }) {
  const [zip, setZip] = React.useState('');
  const [selectedTags, setSelectedTags] = React.useState([]);

  // Extract unique tags from places
  const allTags = Array.from(new Set(places.flatMap((place) => place.tags)));

  // Convert tags to options for react-select
  const tagOptions = allTags.map((tag) => ({ value: tag, label: tag }));

  const handleTagChange = (selectedOptions) => {
    const tags = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    setSelectedTags(tags);
    onFilter(tags);
  };

  const handleRadiusChange = (selectedOption) => {
    const newRadius = selectedOption ? parseInt(selectedOption.value) : 50;
    onRadiusChange(newRadius);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (/^\d{5}$/.test(zip)) { // Validate 5-digit ZIP
      onSearch(zip);
    } else {
      alert('Please enter a valid 5-digit ZIP code.');
    }
  };

  // Options for radius dropdown
  const radiusOptions = [
    { value: 10, label: '10 Miles' },
    { value: 20, label: '20 Miles' },
    { value: 30, label: '30 Miles' },
    { value: 50, label: '50 Miles' },
    { value: 100, label: '100 Miles' },
    { value: 1000, label: '1000 Miles' },
    { value: 4000, label: 'All Country' },
  ];

  const selectedRadiusOption = radiusOptions.find(option => option.value === radius);

  // Custom styles for react-select to fix z-index issues
  const customSelectStyles = {
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <div className="search-filter-container">
      <form onSubmit={handleSubmit} className="search-form">
        <div className="search-group">
          <label htmlFor="zip" className="search-label">
            ZIP Code:
          </label>
          <input
            id="zip"
            type="text"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            required
            pattern="\d{5}"
            title="Five digit zip code"
            placeholder="e.g., 10022"
            className="search-input"
          />
          <button type="submit" className="search-button">Search</button>
        </div>
      </form>
      <div className="filters">
        <div className="filter-group">
          <label htmlFor="radius" className="filter-label">Radius:</label>
          <Select
            id="radius"
            name="radius"
            options={radiusOptions}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select radius..."
            onChange={handleRadiusChange}
            value={selectedRadiusOption}
            styles={customSelectStyles}
            menuPortalTarget={document.body} // Ensures the menu renders above other elements
          />
        </div>
        <div className="filter-group">
          <label htmlFor="tags" className="filter-label">Tags:</label>
          <Select
            id="tags"
            isMulti
            name="tags"
            options={tagOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select"
            onChange={handleTagChange}
            value={tagOptions.filter((option) => selectedTags.includes(option.value))}
            styles={customSelectStyles}
            menuPortalTarget={document.body} // Ensures the menu renders above other elements
          />
        </div>
      </div>
    </div>
  );
}

export default SearchFilter;
