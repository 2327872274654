// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Footer.css */
.footer {
  text-align: center;
  padding: 10px;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.5); /* Barely visible text */
  font-size: 8px;
  position: relative; /* Changed from fixed to relative */
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;EACE,kBAAkB;EAClB,aAAa;EACb,6BAA6B;EAC7B,yBAAyB,EAAE,wBAAwB;EACnD,cAAc;EACd,kBAAkB,EAAE,mCAAmC;EACvD,WAAW;AACb","sourcesContent":["/* src/components/Footer.css */\n.footer {\n  text-align: center;\n  padding: 10px;\n  background-color: transparent;\n  color: rgba(0, 0, 0, 0.5); /* Barely visible text */\n  font-size: 8px;\n  position: relative; /* Changed from fixed to relative */\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
